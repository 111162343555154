import { IBaseConfig } from "@/ioc/types";
import axios from "axios";
import { toQueryString } from "@/cms-services/helpers";
import { ListDataSource } from "./ListDataSource";

export class DocumentListDataSource extends ListDataSource {
  constructor(config: IBaseConfig) {
    super({
      className: "document",
      config,
    });
  }

  public async convertFilesToDocument(id: number, folderId: number) {
    try {
      await axios.post(
        `${this.baseUrl}/${id}/convert/files/${toQueryString({ folderId })}`,
      );
    } catch (error) {
      throw error;
    }
  }
}


import { Vue, Component, Ref } from "vue-property-decorator";
import { DocumentListDataSource } from "@/data/List/DocumentListDataSource";
import { getInstaceStateName } from "@/cms-services/helpers";
import DataList from "@/components/common/data/list.vue";
@Component
export default class Home extends Vue {
  @Ref("dataList") dataList!: DataList;
  getName: any = getInstaceStateName;
  loading: boolean = false;
  dataSource: DocumentListDataSource = new DocumentListDataSource({
    pageIndex: 1,
    pageSize: 50,
  });

  folderConfig: any = {
    className: "documentFolder",
    rootFolder: "mainFolder",
  };
  $message: any;

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Документы",
        disabled: true,
      },
    ];
  }

  async convert({ item, currentFolderId, executeAsyncRequest }) {
    if (!currentFolderId) {
      return this.$message("Пожалуйста выберите папку", "error");
    }
    await executeAsyncRequest(async () => {
      await this.dataSource.convertFilesToDocument(item.id, currentFolderId);
      await this.dataList.refreshAll();
    }, item.id);

    this.$message("Структура успешно сконвертировано");
  }
}
